import React, { Component } from "react";
import "./ProcessingComplete.css";

class ProcessingComplete extends Component {
  state = {};

  handleFocus = (event) => {
    //console.log(event);
    event.target.select();
  };

  copyLink = () => {
    this.inputElement.select();
    try {
      document.execCommand("copy");
      console.log("Copy to clipboard success.");
    } catch (ex) {
      console.warn("Copy to clipboard failed.");
    }
  };

  DownloadBuckup = () => {
    console.log(this.props.Link);
    window.location = this.props.Link;
  };

  render() {
    return (
      <div className="ProcessingCompleteContainer FullPageContainer AppBackground">
        {this.props.ProcessType === "Export" && (
          <div>
            <img
              src={require("./imgs/ExportComplete.svg")}
              alt="Backup Complete"
              className="CompleteImage"
            ></img>
            <div className="CompleteTitle">We did it!</div>
            <div className="CompleteDesc">
              Your excellent music is now available to be downloaded as a file
              from this link:
            </div>
            <input
              onFocus={this.handleFocus}
              className="CompleteLink"
              readOnly
              value={this.props.Link}
              ref={(input) => (this.inputElement = input)}
            ></input>
            <div className="CompletedBtnsContainer">
              <div
                className="CopyLinkButton CompleteButton"
                onClick={this.copyLink}
              >
                <div className="CopyLinkButtonLogo"></div>
              </div>
              <div
                className="DownloadBuckupButton CompleteButton"
                onClick={this.DownloadBuckup}
              >
                <div className="DownloadBuckupButtonLogo"></div>
              </div>
            </div>
            <div className="linkExperationDesc">
              This link will be active for 24 hours
            </div>
          </div>
        )}
        {this.props.ProcessType === "Import" && (
          <div>
            <img
              src={require("./imgs/ImportComplete.svg")}
              alt="Backup Complete"
              className="CompleteImage"
            ></img>
            <div className="CompleteTitle">We did it!</div>
            <div className="CompleteDesc">
              Your new music is waiting for you in your spotify account
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ProcessingComplete;
