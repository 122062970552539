import React, { Component } from "react";
import "./Explanation.css";

class Explanation extends Component {
  state = {};
  render() {
    return (
      <div className="ExplanationContainer">
        <div className="ContentContainer">
          <div className="ExplanationTitle">Export your music from spotify</div>
          <div className="ExplanationDesc">
            You work very hard to build your music library. Years of liking new
            songs, building unique playlists and save the sound track of your
            life. Therefore, it is very important to backup your Spotify library for
            any case, including backuping your Spotify playlists. Our service lets
            you export your Spotify playlists and songs to a json file so you
            can store it in a safe place.
          </div>
          <div className="ExplanationTitle">Upload your music to spotify</div>
          <div className="ExplanationDesc">
            Upload the music you backuped to your Spotify account. Backuping your
            Spotify playlists doesn't worth a penny if you can't restore it back
            into your account. With our service you can restore from your backup
            files. Just upload the json file we created for you back again into
            Spotify.
          </div>
          <div className="ExplanationTitle">How to: step by step</div>
          <div className="ExplanationDesc">
            We know you can figure it out, but here it is anyway
          </div>
          <div className="ExplanationStep"> 1. </div>
          <div className="ExplanationDesc ExplanationTabDesc">
            Click on <b>Export from Spotify</b> button.
          </div>
          <div className="ExplanationStep"> 2. </div>
          <div className="ExplanationDesc ExplanationTabDesc">
            Choose between exporting your entire music library or just a single playlist.
          </div>
          <div className="ExplanationStep"> 3. </div>
          <div className="ExplanationDesc ExplanationTabDesc">
            Wait while the backup is processing. Once finished - download the json backup file and 
            <b> Store it in a safe place</b>!
          </div>
          <div className="ExplanationStep"> 4. </div>
          <div className="ExplanationDesc ExplanationTabDesc">
            After a while, when you need it, take your backup file and restore
            it by clicking the <b>Upload to Spotify</b> button
          </div>
          <div className="ExplanationTitle">Frequently asked questions</div>
          <div className="ExplanationDesc">
            Don't worry, you're not the only one who asked these ones
          </div>
          <div className="ExplanationQustionHeader"> Q: </div>
          <div className="ExplanationDesc ExplanationTabDesc ExplanationQuestion">
            Where are my backup saved?
          </div>
          <div className="ExplanationAnswerHeader"> A: </div>
          <div className="ExplanationDesc ExplanationTabDesc">
            You will get a json file. You can save the file wherever you want
            on your machine or on the cloud.
          </div>
          <div className="ExplanationQustionHeader"> Q: </div>
          <div className="ExplanationDesc ExplanationTabDesc ExplanationQuestion">
            Does it cost money?
          </div>
          <div className="ExplanationAnswerHeader"> A: </div>
          <div className="ExplanationDesc ExplanationTabDesc">
            Right now it is free to use!
          </div>
        </div>
      </div>
    );
  }
}

export default Explanation;
