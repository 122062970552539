import React, { Component } from "react";
import "./ProgressBar.css";

const ProgressBar = (props) => {
  const { completed } = props;

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    borderRadius: "inherit",
    textAlign: "right",
  };

  return (
    <div className="ProgressBarContainer">
      <div className="ProgressBarFiller" style={fillerStyles}></div>
    </div>
  );
};

export default ProgressBar;
