import React, { Component } from "react";
import "./main.css";

class Main extends Component {
  state = {};
  render() {
    return (
      <div className="Main AppBackground">
        <div className="ContentContainer">
          <h1>
            <div className="h1Part1"> Backup </div>
            <div className="h1Part2"> your</div>
            <div className="h1Part3"> vibez</div>
          </h1>
          <div className="RightContainer">
            <h2>
              Export your Spotify music or <br /> add music to your Spotify
            </h2>
            <div className="MainButton" onClick={this.props.Export}>
              <img
                style={{ marginRight: 11.5, marginLeft: 9 }}
                alt=""
                className="MainButtonLogo"
                src={require("./imgs/ExportLogo.svg")}
              ></img>
              Export from spotify
            </div>
            <div className="MainButton" onClick={this.props.Import}>
              <img
                style={{ marginRight: 15.5, marginLeft: -10 }}
                alt=""
                className="MainButtonLogo"
                src={require("./imgs/ImportLogo.svg")}
              ></img>
              upload to spotify
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
