import React, { Component } from "react";
import "./Processing.css";
import ProgressBar from "./ProgressBar";

class Processing extends Component {
  state = {};
  render() {
    return (
      <div className="ProcessingBackupContainer FullPageContainer AppBackground">
        {this.props.ProcessType === "Export" && (
          <img
            src={require("./imgs/ExportProccessing.svg")}
            alt="Backup Processing"
            className="MainProcessingImage"
          ></img>
        )}

        {this.props.ProcessType === "Import" && (
          <img
            src={require("./imgs/ImportProccessing.svg")}
            alt="Import Processing"
            className="MainProcessingImage"
          ></img>
        )}

        {this.props.Status === "readingLists" && (
          <div>
            <div className="readingListsTitle">Great choice!</div>
            <div className="readingListsDesc">
              We're getting ready to start...
            </div>
          </div>
        )}

        {this.props.Status === "processing" && (
          <div>
            <div className="ProccessingSummary">
              {this.props.CurrTask} / {this.props.TotalTasks}
            </div>

            {this.props.ProcessType === "Export" && (
              <div className="ProccessingDesc">
                Great choice! your spotify music is on it's way to be a brand
                new file
              </div>
            )}

            {this.props.ProcessType === "Import" && (
              <div className="ProccessingDesc">
                Great music! in a few moments your spotify will be pleased
              </div>
            )}

            <ProgressBar
              className="ProccessingProgress"
              completed={(this.props.CurrTask / this.props.TotalTasks) * 100}
            ></ProgressBar>
            <div className="CurrProccessing">{this.props.CurrTaskName}</div>
          </div>
        )}
      </div>
    );
  }
}

export default Processing;
