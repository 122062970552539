import React, { Component } from "react";
import "./ImportSettings.css";

class ImportSettings extends Component {
  state = {};
  render() {
    return (
      <div className="ImportSettingsContianer FullPageContainer AppBackground">
        <img
          src={require("./imgs/ImportPageLogo.svg")}
          alt="Backup Complete"
          className="ImportSettingImage"
        ></img>
        <div className="ImportSettingsTitle">
          Your spotify wants more music. select files to add to your spotify
        </div>
        <form
          className="dragArea"
          onClick={this.dragAreaClick}
          onDragOver={this.dragOver}
          onDragEnter={this.dragEnter}
          onDragLeave={this.dragLeave}
          onDrop={this.fileDrop}
          ref={(form) => (this.UploadFileform = form)}
        >
          <div className="ImportDragAreaDesc">Drop it like it's hot</div>
          <div className="dragImageContainer">
            <div className="dragImage"> </div>
          </div>
          <input
            type="file"
            id="fileselect"
            className="importFileInput"
            name="fileselect"
            accept=".json"
            onChange={this.OnFileChanged}
            ref={(input) => (this.inputElement = input)}
          />
          <button className="importSubmitbutton" type="submit">
            Upload Files
          </button>
        </form>

        <div className="UploadButton" onClick={this.ClickUpload}>
          <img
            src={require("./imgs/ImportBlueLogo.svg")}
            className="UploadButtonImage"
          ></img>
          SELECT FILES TO UPLOAD
        </div>
      </div>
    );
  }

  dragAreaClick = (e) => {
    this.inputElement.click();
  };

  OnFileChanged = (e) => {
    this.props.HandleUpload(this.inputElement.files[0]);
  };

  dragOver = (e) => {
    e.preventDefault();
  };

  dragEnter = (e) => {
    e.preventDefault();
  };

  dragLeave = (e) => {
    e.preventDefault();
  };

  fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    console.log(files);
    this.props.HandleUpload(files[0]);
  };

  ClickUpload = () => {
    this.inputElement.click();
  };
}

export default ImportSettings;
