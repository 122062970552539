import React, { Component } from "react";
import "./nav.css";

class Nav extends Component {
  state = {};

  render() {
    return (
      <div className="mainNav">
        <div className="ContentContainer">
          <img
            className="MainLogo"
            src={require("./imgs/logo.svg")}
            alt="Music Backuper"
            onClick={this.props.Home}
          ></img>
          {this.props.ShowButtons && (
            <div style={{ float: "right" }}>
              <div className="HeaderLink" onClick={this.props.Import}>
                Upload
              </div>
              <div className="HeaderLink" onClick={this.props.Export}>
                Export
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Nav;
