import React, { Component } from "react";
import "./App.css";
import Nav from "./nav";
import Explanation from "./Explanation";
import Main from "./Main";
import ExportSettings from "./ExportSettings";
import Fotter from "./Fotter";
import Processing from "./Processing";
import ProcessingComplete from "./ProcessingComplete";
import ImportSettings from "./ImportSettings";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMainPage: true,
      isExportSettingsPage: false,
      isImportSettingsPage: false,
      isProcessingPage: false,
      ProcessingType: "",
      ProcessingId: "",
      ProcessingTotalTask: 1,
      ProcessingCurrTask: 0,
      ProcessingCurrTaskName: "",
      ProcessingStatus: "Init",
      PlatformCode: "",
    };
  }

  getCallbackUrl() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return "http://localhost:3000/callback.html";
    } else {
      return "https://www.musicbackuper.com/callback.html";
    }
  }
  popupCenter(url, title, w, h) {
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );

    if (window.focus) newWindow.focus();

    return newWindow;
  }

  OpenSpotifyLogin = () => {
    const callbackUrl = this.getCallbackUrl();

    const new_window = this.popupCenter(
      "https://accounts.spotify.com/authorize/?client_id=0c095d7cb67d4105bea0de36ba179f47&redirect_uri=" +
        encodeURI(callbackUrl) +
        "&response_type=code&scope=user-read-private+user-library-read+user-follow-read+playlist-read-private+playlist-read-collaborative+playlist-modify-public+playlist-modify-private+user-library-modify+user-follow-modify",
      "Spotify login",
      350,
      650
    );

    return new_window;
  };

  EnterProcessing = (result, ProcessType) => {
    this.setState({
      ...this.state,
      isMainPage: false,
      isExportSettingsPage: false,
      isProcessingPage: true,
      ProcessingType: ProcessType,
      ProcessingId: result.id,
      ProcessingTotalTask: result.total,
      ProcessingCurrTask: result.currentTaskNumber,
      ProcessingCurrTaskName: result.currentTaskName,
      ProcessingStatus: result.status,
    });

    if (ProcessType === "Export") {
      this.CheckBackupStatus();
      this.state.interval = setInterval(this.CheckBackupStatus, 1500);
    } else {
      this.CheckRestoreStatus();
      this.state.interval = setInterval(this.CheckRestoreStatus, 1500);
    }
  };

  CheckBackupStatus = () => {
    this.CheckProcessingStatus("https://www.musicbackuper.com/api/backup/");
  };

  CheckRestoreStatus = () => {
    this.CheckProcessingStatus("https://www.musicbackuper.com/api/restore/");
  };

  CheckProcessingStatus = (APILink) => {
    fetch(APILink + this.state.ProcessingId, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);

        this.setState({
          ...this.state,
          ProcessingTotalTask: result.total,
          ProcessingCurrTask: result.currentTaskNumber,
          ProcessingCurrTaskName: result.currentTaskName,
          ProcessingStatus: result.status,
        });

        if (result.status === "complete") {
          clearInterval(this.state.interval);
        }
      });
  };

  ExportLib = () => {
    const new_window = this.OpenSpotifyLogin();
    new_window.onbeforeunload = () => {
      fetch("https://www.musicbackuper.com/api/backup", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ code: new_window.code }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.EnterProcessing(result, "Export");
        });
    };
  };

  ExportURL = (link) => {
    fetch("https://www.musicbackuper.com/api/backup", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ URL: link }),
    })
      .then((res) => res.json())
      .then((result) => {
        this.EnterProcessing(result, "Export");
      });
  };

  Export = () => {
    this.setState({
      ...this.state,
      isMainPage: false,
      isExportSettingsPage: true,
    });
  };

  Import = () => {
    const new_window = this.OpenSpotifyLogin();
    new_window.onbeforeunload = () => {
      this.setState({
        ...this.state,
        isMainPage: false,
        isImportSettingsPage: true,
        PlatformCode: new_window.code,
      });
    };
  };

  Home = () => {
    this.setState({
      ...this.state,
      isMainPage: true,
      isExportSettingsPage: false,
      isImportSettingsPage: false,
      isProcessingPage: false,
      ProcessingType: "",
      ProcessingId: "0",
      ProcessingTotalTask: 1,
      ProcessingCurrTask: 0,
      ProcessingCurrTaskName: "",
      ProcessingStatus: "Init",
      PlatformCode: "",
    });
  };

  handleUploadfile = (file) => {
    this.setState({
      ...this.state,
      isMainPage: false,
      isExportSettingsPage: false,
      isImportSettingsPage: false,
      isProcessingPage: true,
      ProcessingType: "Import",
      ProcessingId: "0",
      ProcessingTotalTask: 1,
      ProcessingCurrTask: 0,
      ProcessingCurrTaskName: "",
      ProcessingStatus: "readingLists",
    });

    const data = new FormData();
    data.append("file", file);
    fetch("https://www.musicbackuper.com/api/upload/", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: data,
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        fetch("https://www.musicbackuper.com/api/restore", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            id: result.filename.substring(0, result.filename.length - 5),
            code: this.state.PlatformCode,
          }),
        })
          .then((res) => res.json())
          .then((result) => {
            this.EnterProcessing(result, "Import");
          });
      });
  };

  render() {
    return (
      <div>
        <Nav
          Export={this.Export}
          Import={this.Import}
          Home={this.Home}
          ShowButtons={this.state.isMainPage}
        ></Nav>
        <img
          alt=""
          className="fixLeft"
          src={require("./imgs/LeftBackground.svg")}
        ></img>
        <img
          alt=""
          className="fixRight"
          src={require("./imgs/LeftBackground.svg")}
        ></img>

        {this.state.isMainPage && (
          <Main Export={this.Export} Import={this.Import}></Main>
        )}
        {this.state.isMainPage && <Explanation></Explanation>}
        {this.state.isExportSettingsPage && (
          <ExportSettings
            ExportLib={this.ExportLib}
            ExportURL={this.ExportURL}
          ></ExportSettings>
        )}

        {this.state.isProcessingPage &&
          this.state.ProcessingStatus != "complete" && (
            <Processing
              ProcessType={this.state.ProcessingType}
              Status={this.state.ProcessingStatus}
              TotalTasks={this.state.ProcessingTotalTask}
              CurrTask={this.state.ProcessingCurrTask}
              CurrTaskName={this.state.ProcessingCurrTaskName}
            ></Processing>
          )}

        {this.state.isProcessingPage &&
          this.state.ProcessingStatus === "complete" && (
            <ProcessingComplete
              ProcessType={this.state.ProcessingType}
              Link={
                "https://www.musicbackuper.com/backups/" +
                this.state.ProcessingId +
                ".json"
              }
            ></ProcessingComplete>
          )}

        {this.state.isImportSettingsPage && (
          <ImportSettings HandleUpload={this.handleUploadfile}></ImportSettings>
        )}

        <Fotter></Fotter>
      </div>
    );
  }
}

export default App;
