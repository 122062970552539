import React, { Component } from "react";
import "./ExportSettings.css";

class ExportSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      URL: "",
    };
  }

  updateInput = (evt) => {
    this.setState({
      ...this.state,
      URL: evt.target.value,
    });
  };

  render() {
    return (
      <div className="FullPageContainer AppBackground">
        <div className="userName">Hi</div>
        <div className="ExportSettingsExplenation">
          Exporting everything or just parts?
        </div>
        <div className="ExportLibraryButton" onClick={this.props.ExportLib}>
          <div className="ExportButtonLogo"></div>
          Export entire library
        </div>
        <div className="ExportSettingsOr">or</div>
        <input
          className="ExportUrlInput"
          placeholder="Paste Spotify playlist url here"
          onFocus={(e) => (e.target.placeholder = "")}
          onBlur={(e) =>
            (e.target.placeholder = "Paste Spotify playlist url here")
          }
          onChange={this.updateInput}
        ></input>
        {this.state.URL !== "" && (
          <div
            className="ExportLinkButton"
            onClick={() => this.props.ExportURL(this.state.URL)}
          >
            <div className="ExportLinkButtonImg"></div>
          </div>
        )}
      </div>
    );
  }
}

export default ExportSettings;
