import React, { Component } from "react";
import "./Fotter.css";

class Fotter extends Component {
  state = {};
  render() {
    return (
      <div className="FotterContainer">
        <div className="ContentContainer">
          <img
            className="FotterLogo"
            src={require("./imgs/FotterLogo.svg")}
            alt="Music Backuper"
          ></img>
          <div
            style={{
              float: "right",
              color: "white",
              fontSize: 12,
              fontFamily: "Raleway-Regular",
              textAlign: "right",
              marginTop: 5,
            }}
          >
            © 2020 musicbeckuper
          </div>
        </div>
      </div>
    );
  }
}

export default Fotter;
